import React from 'react';
// Theme's default styles, purged through purge.css
import '../styles/theme/bootstrap.micro.css';
import '../styles/theme/index.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Layout = ({ children }) => {
    return (
        <>
            <Navbar />
            <main>{children}</main>
            <Footer />
        </>
    );
};

export default Layout;
