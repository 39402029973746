import React from 'react';

const SUBMIT_STATES = {
    NOT_SUBMITTED: 0,
    SUCCESS: 1,
    ERROR: 2,
};
const ContactForm = () => {
    const [submitted, setSubmitted] = React.useState({ state: SUBMIT_STATES.NOT_SUBMITTED });

    async function handlesSubmit(e) {
        e.preventDefault();

        const elementsArray = [...e.target.elements];
        const formData = elementsArray.reduce((acc, elem) => {
            if (elem.name) {
                acc[elem.name] = elem.value;
            }

            return acc;
        }, {});

        const request = await fetch('https://formspree.io/f/xoqbjwbw', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                Accept: 'application/json',
            },
        });
        const response = await request.json();

        if (request.status === 200) {
            setSubmitted({ state: SUBMIT_STATES.SUCCESS });
        } else {
            alert('There was an error submitting this form');
            setSubmitted({ state: SUBMIT_STATES.ERROR });
        }
    }

    if (submitted.state === SUBMIT_STATES.SUCCESS) {
        return (
            <div className="subscribe-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="contact-form">
                            <h2 class="contact-form__title">Your info has been sent</h2>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="subscribe-area">
            <div className="container">
                <div className="row align-items-center">
                    <div class="contact-form">
                        <h2 class="contact-form__title">Contact us</h2>
                        <form className="newsletter-form" onSubmit={handlesSubmit}>
                            <div>
                                <label htmlFor="email" className="sr-only">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    className="input-newsletter form-control"
                                    placeholder="Your email"
                                    name="email"
                                    id="email"
                                    required
                                    autocomplete="off"
                                />
                            </div>
                            <div>
                                <label htmlFor="name" className="sr-only">
                                    Name
                                </label>
                                <input
                                    type="name"
                                    className="input-newsletter form-control"
                                    placeholder="Your Name"
                                    name="name"
                                    required
                                    id="name"
                                />
                            </div>
                            <div>
                                <label htmlFor="message" className="sr-only">
                                    Message
                                </label>
                                <textarea
                                    type="message"
                                    className="input-newsletter form-control"
                                    placeholder="Your message"
                                    name="message"
                                    id="message"
                                    rows="4"
                                ></textarea>
                            </div>
                            <button type="submit">Contact Us</button>
                            <div id="validator-newsletter" className="form-result"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactForm;
