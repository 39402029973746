import React, { useEffect, useState } from 'react';
import { HiOutlineMenu } from 'react-icons/hi';
import { MdClose } from 'react-icons/md';
import { useInView } from 'react-intersection-observer';

const offset = 20;
const navItems = [
    {
        name: 'Services',
        url: '#services',
    },
    {
        name: 'About Us',
        url: '#about-us',
    },
    {
        name: 'Why Us',
        url: '#why-us',
    },
    {
        name: 'Why Trust Us',
        url: '#trust-us',
    },
    {
        name: 'Hire Us',
        url: '#hire-us',
    },
    {
        name: 'Contact',
        url: '#contact-us',
    },
];
const Navbar = () => {
    const [ref, inView] = useInView({ threshold: 0 });
    const [isSticky, setSticky] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        setSticky(offset <= window.scrollY);
    }, []);

    useEffect(() => {
        setSticky(!inView);
    }, [inView]);

    return (
        <>
            <div
                ref={ref}
                style={{
                    height: '1px',
                    width: '50vw',
                    position: 'absolute',
                    top: `${offset}px`,
                }}
            />
            <div className={`navbar-area ${isSticky ? 'sticked' : ''}`}>
                <div className="techvio-responsive-nav">
                    <div className="container">
                        <div className="techvio-responsive-menu">
                            <div className="logo">
                                <a href="/">
                                    <img
                                        src="assets/img/lotus-logo-white.png"
                                        className="white-logo"
                                        alt="logo"
                                    />
                                </a>
                            </div>
                            <button
                                className="nav-toggle-button"
                                onClick={() => setIsExpanded((prev) => !prev)}
                                aria-label={isExpanded ? 'Close navigation' : 'Open navigation'}
                            >
                                {isExpanded ? (
                                    <MdClose color="white" size={32} />
                                ) : (
                                    <HiOutlineMenu color="white" size={32} />
                                )}
                            </button>
                        </div>
                    </div>
                </div>
                <div className={`techvio-nav ${isExpanded ? 'expanded' : ''}`}>
                    <div className="container">
                        <nav className="navbar navbar-expand-md navbar-light">
                            <a className="navbar-brand" href="index.html">
                                <img
                                    src="assets/img/lotus-logo-white.png"
                                    className="white-logo"
                                    alt="logo"
                                />
                            </a>
                            <div
                                className="collapse navbar-collapse mean-menu"
                                id="navbarSupportedContent"
                            >
                                <ul className="navbar-nav">
                                    {navItems.map((item) => {
                                        return (
                                            <li className="nav-item" key={item.name}>
                                                <a href={item.url}>{item.name}</a>
                                            </li>
                                        );
                                    })}
                                </ul>
                                <div className="other-option">
                                    <a className="default-btn" href="#contact-us">
                                        Let's Talk!<span></span>
                                    </a>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Navbar;
