import React from 'react';
import { BsPinterest, BsTelephone, BsTwitter } from 'react-icons/bs';
import { ImLinkedin } from 'react-icons/im';
import { HiOutlineMail as HiMail } from 'react-icons/hi';
import { TbMapPin } from 'react-icons/tb';
import { FaFacebookF } from 'react-icons/fa';
import ContactForm from './ContactForm';

const Footer = () => {
    return (
        <footer>
            <section id="contact-us" className="footer-subscribe-wrapper">
                <ContactForm />
                <div className="footer-area ptb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-md-6 col-sm-6">
                                <div className="single-footer-widget">
                                    <div className="footer-heading">
                                        <h3>About Us</h3>
                                    </div>
                                    <p>
                                        <b>
                                            We are industry professionals with a passion for the web.
                                        </b>
                                    </p>
                                    <p>
                                        With combined experience of over four decades in advertising, consulting, Fortune XXs and small busy fast growing companies. Our care to detail for clean code, intuitive UI, and SEO is your benefit. Give us the laundry list and let us put your minds at ease.
                                    </p>
                                    <ul className="footer-social">
                                        <li>
                                            <a href="https://www.linkedin.com/company/lotus-dev/" aria-label="Visit Linkedin" target="_blank">
                                                {' '}
                                                <ImLinkedin size={16} />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="single-footer-widget">
                                    <div className="footer-heading">
                                        <h3>Our Services</h3>
                                    </div>
                                    <ul className="footer-quick-links">
                                        <li>
                                            {' '}
                                            <a href="#services">Services</a>
                                        </li>
                                        <li>
                                            {' '}
                                            <a href="#about-us">About Us</a>
                                        </li>
                                        <li>
                                            {' '}
                                            <a href="#why-us">Why Us</a>
                                        </li>
                                        <li>
                                            {' '}
                                            <a href="#trust-us">Why Trust Us</a>
                                        </li>
                                        <li>
                                            {' '}
                                            <a href="#hire-us">Hire Us</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-footer-widget">
                                    <div className="footer-heading">
                                        <h3>Contact Info</h3>
                                    </div>
                                    <div className="footer-info-contact">
                                        {' '}
                                        <BsTelephone size={20} />
                                        <h3>Phone</h3>
                                        <span>
                                            <a href="#contact">Request a callback</a>
                                        </span>
                                    </div>
                                    <div className="footer-info-contact">
                                        {' '}
                                        <HiMail size={20} />
                                        <h3>Email</h3>
                                        <span>
                                            <a href="mailto:hello@lotus-dev.com">
                                                hello@lotus-dev.com
                                            </a>
                                        </span>
                                    </div>
                                    <div className="footer-info-contact">
                                        {' '}
                                        <TbMapPin size={20} />
                                        <h3>Address</h3>
                                        <span>
                                            Palo Alto, California, USA<br />
                                            Berlin, Germany<br />
                                            San Jose, Costa Rica
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="copyright-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <p>&#169; 2022 Lotus Dev - All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
